const getters = {
  languageType: (state) => state.system.languageType,
  locale: (state) => state.system.locale,
  theme: (state) => state.system.theme,
  userInfo: (state) => state.user.userInfo,
  needLogin: (state) => state.user.needLogin,
  suburbInfo: (state) => state.suburb.suburbInfo,
  wikiTitle: (state) => state.suburb.wikiTitle,
  refresh: (state) => state.suburb.refresh,
  submitLock: (state) => {
    return function(path) {
      if (!path) {
        return !!state.locker.submit;
      }
      return path === state.locker.submit;
    }
  }
};
export default getters;