import { createStore } from "vuex";
import getters from "./getters";
import state from "./state";
import user from "./modules/user";
import locker from "./modules/locker";
import system from "./modules/system";
import suburb from "./modules/suburb";
import createPersistedState from "vuex-persistedstate";
export default createStore({
  modules: {
    user,
    locker,
    system,
    suburb
  },
  getters,
  state,
  plugins: [createPersistedState()],
});
