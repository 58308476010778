import moment from "moment";

const state = {
  suburbInfo: {
    fullSuburb: null,
    suburb: null,
    state: null,
    lga: null
  },
  wikiTitle: null,
  refresh: null,
};

const mutations = {
  SET_SUBURB_INFO(state, value) {
    state.suburbInfo = value;
  },
  SET_REFRESH(state, value) {
    state.refresh = value;
  },
  SET_LGA(state, value) {
    state.suburbInfo.lga = value;
  },
  WIKI_TITLE(state, value) {
    state.wikiTitle = value;
  },
};

const stateMapping = {
  'NSW': 'New South Wales',
  'VIC': 'Victoria',
  'QLD': 'Queensland',
  'SA': 'South Australia',
  'WA': 'Western Australia',
  'TAS': 'Tasmania',
  'ACT': 'Australian Capital Territory',
  'NT': 'Northern Territory'
};

const actions = {
  setSuburbInfo({ commit }, params) {
    return new Promise((resolve) => {
      if (params) {
        let fullSuburb = params;

        const suburbRegex = /^(.*?)\s[A-Z]{2,}, Australia/;
        let matches = params.match(suburbRegex);
        let suburb = matches ? matches[1] : null;

        if (suburb) {
          params = params.replace(suburb, '')
        }
        const stateRegex = /\b(ACT|NSW|NT|QLD|SA|TAS|VIC|WA)\b/i;
        matches = params.match(stateRegex);
        let state = matches ? matches[1] : null;
        commit("SET_SUBURB_INFO", { fullSuburb, suburb, state });
        commit("WIKI_TITLE", suburb + ", " + stateMapping[state]);
        commit("SET_REFRESH", moment.now())
      }
      resolve();
    });
  },
  setRefresh({ commit }, params) {
    commit("SET_REFRESH", moment.now())
  },
  setLga({ commit }, params) {
    commit("SET_LGA", params);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
