import { signIn } from "@/axios/authApi";
import Storage from "@/utils/storage";

const state = {
  userInfo: {},
  needLogin: false
};

const mutations = {
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_NEED_LOGIN: (state, value) => {
    state.needLogin = value;
  },
};

const actions = {
  // 登录
  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      signIn(params).then(res => {
        if (res.success) {
          const { data } = res
          Storage.set('token', data.access_token);
          commit("SET_USER_INFO", data);
          commit("SET_NEED_LOGIN", false);
          resolve(res);
        } else {
          reject(res.message)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  
  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      Storage.remove('token');
      commit("SET_USER_INFO", {});
      resolve()
    })
  },

  // 修改主题
  setNeedLogin({ commit }, params) {
    return new Promise((resolve) => {
      commit("SET_NEED_LOGIN", params);
      if (params) {
        commit("SET_USER_INFO", {});
      }
      resolve();
    });
  },

};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
