import global from "@/assets/js/global";

const env = process.env.NODE_ENV
const config = (() => {
	if (env === "production") {
		if (global.environment === "production") {
			return {
				baseUrl: "https://service.arcnet.com.au/arc-ai-api/api",
			}
		} else {
			return {
				baseUrl: "https://staging.arcnet.com.au/arc-ai-api/api",
			}
		}
	} else {
		return {
			baseUrl: "/app",
		}
	}
})()

export default config
