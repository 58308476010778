const pcRouter = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    redirect: "/home",
    children: [{
        path: "/home",
        name: "home",
        meta: { auth: false, breadNumber: 0, title: 'home' },
        component: () => import("@/views/mobile/home/Index"),
      },
    ]
  },
];
export default pcRouter;
