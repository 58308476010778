import axios from "axios";
// import router from "@/router";
import store from "@/store";
import Storage from "@/utils/storage";
import config from "@/assets/js/config";
import mockData from "./mockData";

const service = axios.create({
  baseURL: config.baseUrl, // url = base url + request url
	timeout: 3 * 60 * 1000,
	headers: {
		'Content-Type': 'application/json; charset=utf-8;'
	},
	withCredentials : true
})

service.interceptors.request.use(config => {
	// 在请求发送之前动态设置 Authorization header
	const token = Storage.get('token');
	if (token) {
		config.headers['Authorization'] = 'Bearer ' + token;
	}
	if (store.getters.submitLock(config.url)) {
		// 如果提交被锁则拦截提交
		return Promise.reject(new Error("Processing..."));
	}
	if (config.lock) {
		// 锁定提交
		store.dispatch("locker/lockSubmit", config.url);
	}
	config.data = JSON.stringify(config.params);
	// console.log('request', config.data);

	store.dispatch("user/setNeedLogin", false);

	return config;
}, error => {
	return Promise.reject(error);
})

service.interceptors.response.use(
	response => {
		const res = response;
		// console.log('response' ,JSON.stringify(res))
		if (res.code == 402 || res.code == 403 || res.code == 422) {
			store.dispatch("user/setNeedLogin", true);
			return Promise.reject('error');
		}
		if (store.getters.submitLock(response.config.url)) {
			// 解锁提交
			store.dispatch("locker/unlockSubmit");
		}
		return response.data;
	},
	error => {
		// 解锁提交
		store.dispatch("locker/unlockSubmit");

		// console.log('error', error)
    // 对响应错误进行处理
    if (error.response) {
			if (error.response.status == 401 || error.response.status == 422) {
				store.dispatch("user/setNeedLogin", true);
        return Promise.reject(error);
			}
			if (error.response.status == 404) {
				// 如果返回 404 错误，则切换到使用 Mock 数据
				// console.log('Switching to mock data...', error.config.url);
				// const mockResponse = mockData(error.config.url, error.config.params); // 获取 Mock 数据
				// if (mockResponse) {
				// 	return Promise.resolve({ data: mockResponse }); // 使用 Mock 数据
				// } else {
				// 	// 如果 Mock 数据中不存在请求的 URL，则抛出 404 错误
				// 	return Promise.reject(error);
				// }
			}
			return Promise.reject(error);
    } else {
			// if (error.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
			// } else {
			// 	// 如果返回 404 错误，则切换到使用 Mock 数据
			// 	// console.log('Switching to mock data...', error.config.url);
			// 	const mockResponse = mockData(error.config.url, error.config.params); // 获取 Mock 数据
			// 	if (mockResponse) {
			// 		return Promise.resolve({ data: mockResponse }); // 使用 Mock 数据
			// 	} else {
			// 		// 如果 Mock 数据中不存在请求的 URL，则抛出 404 错误
			// 		return Promise.reject(error);
			// 	}
			// }
			return Promise.reject(error);          // reject这个错误信息
		}
	}
)

export default service;



